$primary: var(--primary, #19b394);

$background: var(--background, #fff);
$background-dim: var(--background-dim, #f3f3f3);

$foreground-light: var(--foreground-light, #909090);
//todo: discuss this variable
$foreground-disabled: var(--foreground-disabled, rgba(#161616, 0.16));

$font-family: "Open Sans";

$base-unit: var(--base-unit, 8px);

@function calcSize($multiplier) {
  $result: calc(#{$multiplier} * #{$base-unit});
  @return $result;
}

.sv-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  background: #ffffff;
  font-family: $font-family;
  list-style-type: none;
}

.sv-list__item {
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: calcSize(1) calcSize(2);
  cursor: pointer;
}

.sv-list__item-icon {
  float: left;
  width: calcSize(3);
  height: calcSize(3);
  svg {
    display: block;
  }
  use {
    fill: $foreground-light;
  }
  margin-right: calcSize(2);
}
.sv-list__item:hover {
  background-color: $background-dim;
}

.sv-list__item--selected {
  background-color: $primary;
  color: $background;
  &:hover {
    background-color: $primary;
  }
  .sv-list__item-icon use {
    fill: $background;
  }
}
.sv-list__item--disabled {
  color: $foreground-disabled;
  cursor: default;
  pointer-events: none;
}

.sv-list__item span {
  white-space: nowrap;
}
