.sv-panel {
  box-sizing: border-box;
  width: 100%;
}
.sv-panel__title {
  font-size: 1.25em;
  margin: 0;
  padding: 0;
  padding-bottom: 0.1em;
  padding-left: 0.44em;
}
.sv-panel__footer {
  margin: 0;
  padding: 1em 0.44em 1em 0;
}
.sv-panel__description {
  padding-left: 0.55em;
}
.sv-panel__title--expandable {
  cursor: pointer;
}
.sv-panel__icon {
  outline: none;
}
.sv-panel__icon:before {
  content: "";
  display: inline-block;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23404040;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
  height: 0.5em;
  width: 0.6em;
  margin-left: 1.5em;
  vertical-align: middle;
}
.sv-panel__icon--expanded:before {
  transform: rotate(180deg);
}
.sv-panel .sv-question__title {
  font-size: 1em;
  padding-left: 0.55em;
}
.sv-panel__content:not(:first-child) {
  margin-top: 0.75em;
}
.sv-panel .sv-row:not(:last-child) {
  padding-bottom: 1.875em;
}
.sv-panel__title--error {
  background-color: $error-background-color;
}
